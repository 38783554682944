




















































import { defineComponent, ComponentInstance, computed } from "@vue/composition-api";
import OpenCardButton from "@monorepo/uikit/src/components/tableViews/OpenCardButton.vue";
import { mapActions, mapGetters } from "vuex";
import { IUser } from "@monorepo/informationSecurity/src/views/ExternalAPIUsers/types/IUser";
import { fields } from "@monorepo/utils/src/variables/projectsData/externalApi/fields";
import { headerCheckboxValue } from "@monorepo/utils/src/variables/headerCheckboxValue";
import useTable from "@monorepo/utils/src/composables/useTable";
import useTableCells from "@monorepo/utils/src/composables/useTableCells";
import useTableAnimation from "@monorepo/utils/src/composables/useTableAnimation";

export default defineComponent({
  components: {
    OpenCardButton,
  },
  data() {
    return {
      fields,
      headerCheckboxValue,
    };
  },
  props: {
    tableHeaders: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters("externalAPIUsers", ["users", "selectedIds", "isSelectAll", "openedUserId", "archivesTree"]),
    ...mapGetters("externalAPIUsers", { data: "users", getCells: "cells" }),
    resultData(): IUser[] {
      return this.users.map((item: IUser) => {
        const archiveIds = (item.archiveList || []).map((archive) => archive.id);
        const fundIds = (item.fundList || []).map((fund) => fund.id);
        const accessesTree = (this.archivesTree || []).map((archive: any) => ({
          ...archive,
          isHasAccess: archiveIds.includes(archive.id),
          funds: (archive.funds || []).map((fund: any) => ({
            ...fund,
            isHasAccess: fundIds.includes(fund.id),
          })),
        }));
        return {
          ...item,
          [fields.USER_FUND]: accessesTree
            .reduce((result: any[], current: any) => {
              if (current.isHasAccess) {
                result.push(current.shortName || "");
                return result;
              }
              current.funds
                .filter((fund: any) => fund.isHasAccess)
                .forEach((fund: any) => {
                  result.push(fund.shortName || "");
                });
              return result;
            }, [])
            .join(", "),
        };
      });
    },
  },
  methods: {
    ...mapActions("externalAPIUsers", ["setSelectedIds", "setIsSelectAll", "addOpenedUserId"]),
    openModal(header: { value: string; isLink: boolean }, event: Record<string, unknown>) {
      switch (header.value) {
        case "openModal":
          if (event[fields.USER_ID]) {
            this.addOpenedUserId(event[fields.USER_ID]);
            this.$emit(header.value, event[fields.USER_IS_DELETED]);
          }
          break;
        case fields.USER_FUND:
          (
            this as unknown as { moveByAuthorities: (header: { isLink: boolean }, path: string, query: Record<string, unknown>) => void }
          ).moveByAuthorities(header, "/dictionaries/fund", {
            name: event[fields.USER_FUND],
            isOpenModal: "1",
          });
          break;
        default:
          break;
      }
    },
  },
  setup(props: Record<string, unknown>, { root }: { root: ComponentInstance }) {
    const tableHeaders = computed(() => props.tableHeaders as unknown[]);
    const { clickCheckbox, isElementDeleted, selectedIdsElements } = useTable(root, "externalAPIUsers", { dataField: "users" });
    const { headers } = useTableCells(root, "externalAPIUsers", tableHeaders);
    const { isShowAnimation, clearAnimation, addAnimation } = useTableAnimation(root);
    return {
      clickCheckbox,
      isElementDeleted,
      selectedIdsElements,
      headers,
      isShowAnimation,
      clearAnimation,
      addAnimation,
    };
  },
});
